import logo from "./logo.svg";
import "./App.css";
import { useEffect } from "react";
import { redirect, useLocation } from "react-router-dom";

function App() {
  const product = "basic_kyc";
  const searchParams = new URLSearchParams(document.location.search);

  console.log(searchParams.get("redirect"));
  useEffect(() => {
    let token = searchParams.get("token");
    let callback_url = searchParams.get("callback_url");
   
    window?.SmileIdentity({
      token,
      product,
      callback_url, // u will get this from the backend
      environment: "live", //remember to change this when u going production
      partner_details: {
        partner_id: "6529",
        name: `Ginyverse`,
        // logo_url: `${your_app_logo_url}`,
        policy_url: `https://ginyverse.com/tandc`, // note that this url goes nowhere, put an actual policy url
        theme_color: "blue", //use anywaka's own color
      },
      onSuccess: () =>
        window.location.replace(searchParams.get("redirect") + "kyc-success"),
      onClose: () =>
        window.location.replace(searchParams.get("redirect") + "kyc-close"),
      onError: console.log
      // id_selection: {
      //   NG: ["BVN_MFA"],
      // },
      // consent_required: {
      //   NG: ["BVN_MFA"],
      // },
      // previewBVNMFA: true,
    
    });
   
  }, []);
  return <div className="App"></div>;
}

export default App;
